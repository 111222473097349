import DatePicker from "react-datepicker";
import styles from "./CustomDatePicker.module.css";


function CustomDatePicker({
  label,
  dateFormat,
  selected,
  onChange,
  placeholderText,
  minVal
}) {
  const customStyles = {
    dateInput: {
      backgroundColor: "transparent", // Set the desired background color
      padding: "8px",
      width: "100%",
      borderRadius: "6px",
      border: "1px solid #5d5d5d",
      // marginTop: "2px",
      fontSize: "0.875rem",
    },
  };
  return (
    <div>
      <label className={styles.label}>{label}</label>
      <DatePicker
        wrapperClassName={styles.csDatePicker}
        dateFormat={dateFormat}
        selected={selected}
        onChange={onChange}
        customInput={<input style={customStyles.dateInput} />}
        placeholderText={placeholderText}
        fixedHeight
        minDate = {minVal? minVal : ""}
      />
    </div>
  );
}

export default CustomDatePicker;
