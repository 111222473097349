import React, { useState, useEffect, useRef } from "react";
import { Form, Row, Col, Button, Container } from "react-bootstrap";
import "./campaignpage.css";
import Common from "../../component/common/common";
import { getcampaignPageData } from "../../features/campaignPageSlice";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { getStateData } from "../../features/stateSlice";
import { getDistrictData } from "../../features/districtSlice";
import { getPincodeData } from "../../features/pincodeSlice";

import Swal from "sweetalert2";
import { Select, Space } from "antd";
import styles from "../Dashboard/Dashboard.module.css";
import { getGroupData } from "../../features/groupSlice";
import { getBrandList } from "../../features/brandSlice";
import { getSubCategoryList } from "../../features/subcategorySlice";
import { getProductList } from "../../features/productSlice";
import { getGroupMasterNewData } from "../../features/GroupMasterNewSlice";
import { getBrandMasterNewData } from "../../features/BrandMasterNewSlice";
import { getSubCategoryMasterNewData } from "../../features/SubCategoryMasterNewSlice";
import { getProductMasterNewData } from "../../features/ProductMasterNewSlice";
import appConfig from "../../AppConfig";

const CampaignBasedContestForm = () => {
  const msLoggedUserId = localStorage.getItem("userId");
  const msLoggedRoleId = localStorage.getItem("roleId");
  const token = localStorage.getItem("logintoken");
  const dispatch = useDispatch();
  const [uniqueId, setUniqueid] = useState("");
  //   console.log("uniqueIddddddddd", uniqueId);
  
  const [stageSelected, setstageSelected] = useState("");
  const [userroleSelected, setUserroleSelected] = useState("All");
  //   console.log("userroleSelectedddddddddd", userroleSelected);
  const [groupnameSelected, setGroupnameSelected] = useState([]);
  const [brandnameSelected, setBrandnameSelected] = useState([]);
  const [subcategorySelected, setSubcategorySelected] = useState([]);
  const [productSelected, setProductSelected] = useState([]);
  const [bonusbenefitSelected, setBonusbenefitSelected] = useState("");
  const [allbonusbenefit, setallbonusbenefit] = useState([]);

  const [selectedFile, setSelectedFile] = useState(null);
  const [submitted, setSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const stateList = useSelector((state) => state.stateList.data);  //this will get stateList when state slice gets updated in store
  const districtList = useSelector((state) => state.districtList.data); //this will get districtList when districtList sliceupdates
  const pincodeList = useSelector((state) => state.pincodeList.data);


  const [districtItemSelected, setDistrictItemSelected] = useState([]); //this stores selected districts' id's
  const [pincodeItemSelected, setPincodeItemSelected] = useState([]); //this stores selected pincode's id's
 

  const [stateItemSelected, setStateItemSelected] = useState([]);   //this stores selected state names
  const [selectedDistrictNames, setSelectedDistrictNames] = useState([]); //this will store names of the districts selected
  const [selectedPincodeNames, setSelectedPincodeNames] = useState([]) //this stores selected pincodes

  const [dynamicVariables, setDynamicVariables] = useState([]);
  const [groupedData, setGroupedData] = useState({}); // Grouped data state
  const [dynamicData, setDynamicData] = useState([]);
  console.log("thi is dynamicdata",dynamicData);  
  


  const groupList = useSelector((state) => state.groupMasterNew.data);
  const brandList = useSelector((state) => state.brandMasterNew.data);
  const subCategoryList = useSelector(
    (state) => state.subCategoryMasterNew.data
  );
  const productList = useSelector((state) => state.productMasterNew.data);

  const benefitList = [
    { id: 1, name: "Fixed" },
    { id: 3, name: "Percentage" },
  ];

  useEffect(() => {
    dispatch(getStateData({ msLoggedUserId, msLoggedRoleId }));
  }, []);

  useEffect(() => {
    dispatch(getGroupMasterNewData(msLoggedRoleId));
  }, [dispatch, msLoggedRoleId]);

  useEffect(() => {
    dispatch(getBrandMasterNewData(msLoggedRoleId));
  }, [dispatch, msLoggedRoleId]);

  useEffect(() => {
    dispatch(getSubCategoryMasterNewData(msLoggedRoleId));
  }, [dispatch, msLoggedRoleId]);

  useEffect(() => {
    dispatch(getProductMasterNewData(msLoggedRoleId));
  }, [dispatch, msLoggedRoleId]);

  useEffect(() => {
    console.log("this is current districtList we got", districtList)
    if (districtList) {
      const districtNames = districtList.map((distobj) => {
        return distobj.district;
      });
      setDistrictItemSelected(districtNames);
    }
  }, []);

  // Initialize all selections with "All"
  useEffect(() => {
    resetToDefault();
  }, []); // Empty dependency array means this runs once when component mounts

  function resetToDefault(){
    setStateItemSelected(["All"]);
    setSelectedDistrictNames(["All"]);
    setSelectedPincodeNames(["All"]);
    setDistrictItemSelected(["alldistrict"]);
    setPincodeItemSelected(["allpincode"]);
    setProductSelected(["All"]);

    // Dispatch initial data fetches
    dispatch(
      getDistrictData({
        msLoggedUserId,
        msLoggedRoleId,
        selectedStates: ["All"],
      })
    );

    dispatch(
      getPincodeData({
        msLoggedUserId,
        msLoggedRoleId,
        selectedDistricts: ["All"],
        selectedStates: ["All"]
      })
    );
  }

  const allstates = stateList.map((stateobj) => {
    return stateobj.state;
  });

  const stateOptionsWithIds = [
    { label: "All", value: "allstates" }, // Add custom "All" option
    ...stateList.map((item) => ({
      label: item.state,
      value: item.id,
    })),
  ];

  const groupMasterListWithIds = [
    { label: "All", value: "allgroup" },
    ...groupList.map((item) => ({
      label: item.group,
      value: item.groupId,
    })),
  ];

  const brandMasterListWithIds = [
    { label: "All", value: "allbrand" },
    ...brandList.map((item) => ({
      label: item.brand,
      value: item.brandId,
    })),
  ];

  const subCategoryMasterListWithIds = [
    { label: "All", value: "allsubcat" },
    ...subCategoryList.map((item) => ({
      label: item.subCategory,
      value: item.subId,
    })),
  ];

  const productMasterListWithIds = [
    { label: "All", value: "allproduct" },
    ...productList.map((item) => ({
      label: item.productName,
      value: item.pId,
    })),
  ];

  const handleStateChange = (selectedIds) => {
    // Remove 'allstates' from the selectedIds array in
    if (selectedIds.includes("allstates")) {
      if (selectedIds[0] === "allstates" && selectedIds.length > 1) {
        // "All" was chosen previously, now we're selecting other states as well
        const allIndex = selectedIds.indexOf("allstates");
        if (allIndex !== -1) {
          selectedIds.splice(allIndex, 1); // Remove 'allstates' from the array
          // setSelectedValues(selectedIds); // Update selected IDs
          const selectedStateNames = selectedIds
            .map((id) => stateList.find((state) => state.id === id)?.state)
            .filter((name) => name !== undefined); // Map IDs to state names
          setStateItemSelected(selectedStateNames); // Update state names

          dispatch(
            getDistrictData({
              msLoggedUserId,
              msLoggedRoleId,
              selectedStates: selectedStateNames,
            })
          );
        }
      } else {
        // Remove "All" and allow other states to be selected
        selectedIds.splice(0, selectedIds.length); // Clear all items in the array
        selectedIds.push("allstates"); // Keep only "allstates" selected
        // setSelectedValues(selectedIds); // Update selected IDs with only "allstates"
        setStateItemSelected(["All"]); // Update state names with "All" only

        dispatch(
          getDistrictData({
            msLoggedUserId,
            msLoggedRoleId,
            selectedStates: ["All"],
          })
        );
        return; // Exit
      }
    }
    console.log("stateList is", stateList);
    console.log("districtList is", districtList);
    console.log("pincodeList is",pincodeList);

    // Case 3: Normal multiselect case (no "All" involved)
    // setSelectedValues(selectedIds); // Update selected IDs
    const selectedStateNames = selectedIds
      .map((id) => stateList.find((state) => state.id === id)?.state)
      .filter((name) => name !== undefined); // Map IDs to state names

    setStateItemSelected(selectedStateNames); // Update state names
    if (selectedStateNames.length > 0) {
      dispatch(
        getDistrictData({
          msLoggedUserId,
          msLoggedRoleId,
          selectedStates: selectedStateNames,
        })
      );
    }
  };

  // console.log("this is selectedValues in global", selectedValues)
  const handleDistrictChange = (selectedValues) => {
  console.log("this is selectedValues in handledistrictchange", selectedValues)

    if (selectedValues.includes("alldistrict")) {
      if (selectedValues[0] === "alldistrict" && selectedValues.length > 1) {
        // "All" was chosen previously, now we're selecting other states as well
        const allIndex = selectedValues.indexOf("alldistrict");
        selectedValues.splice(allIndex, 1); // Remove 'alldistrict' from the array
      } else {
        // if alldistrict was not chosen first
        selectedValues.splice(0, selectedValues.length); // Clear all items in the array
        selectedValues.push("alldistrict"); // Keep only "allstates" selected
      }
    }
    setDistrictItemSelected(selectedValues);
    if( districtItemSelected.length > 0 ){
      dispatch(   //this will call the pincode slice to update pincode data
        getPincodeData({
          msLoggedUserId,
          msLoggedRoleId,
          selectedDistricts: selectedDistrictNames,
          selectedStates: stateItemSelected
        })
      );
    }
  };
  //   console.log("this is districtItemSelected", districtItemSelected);
  const handlePincodeChange = (selectedValues) => {
    if (selectedValues.includes("allpincode")) {
      if (selectedValues[0] === "allpincode" && selectedValues.length > 1) {
        // "All" was chosen previously, now we're selecting other states as well
        const allIndex = selectedValues.indexOf("allpincode");
        selectedValues.splice(allIndex, 1); // Remove 'allpincode' from the array
      } else {
        // if allpincode was not chosen first
        selectedValues.splice(0, selectedValues.length); // Clear all items in the array
        selectedValues.push("allpincode"); // Keep only "allstates" selected
      }
    }
    setPincodeItemSelected(selectedValues); //storing all selected id's in pincodeItemSelected
  }

  const selectedStateIds = stateItemSelected.map((name) =>
    name === "All"
      ? "allstates"
      : stateList.find((item) => item.state === name)?.id
  );

  const selectedGroupMasterList = groupnameSelected.map((name) =>
    name === "All"
      ? "allgroup"
      : groupList.find((item) => item.group === name)?.groupId
  );

  const selectedBrandMasterList = brandnameSelected.map((name) =>
    name === "All"
      ? "allbrand"
      : brandList.find((item) => item.brand === name)?.brandId
  );

  const selectedSubcategoryMasterList = subcategorySelected.map((name) =>
    name === "All"
      ? "allsubcat"
      : subCategoryList.find((item) => item.subCategory === name)?.subId
  );

  const selectedproductMasterList = productSelected.map((name) =>
    name === "All"
      ? "allproduct"
      : productList.find((item) => item.productName === name)?.pId
  );

  const data = useSelector((state) => state.campaignPageData);
  const message = useSelector((state) => state.campaignPageData.message);
  const status = useSelector((state) => state.campaignPageData.status);

  //   console.log("message in campaign page", message);

  const validCount = data?.data?.data?.validCount || 0;
  const inValidCount = data?.data?.data?.invalidCount || 0;

  //   console.log("validCountttttttttt", validCount);

  const [formData, setFormData] = useState({
    campaignType: "",
    campaignName: "",
    bonusRemarks: "",
    benefitType: "instant",
    userSelection: "",
    reward: "",
    userPreference: "",
    runType: "",
    limit: "",
  });

  //modifies startdate
  const [startDate, setStartDate] = useState(() => {
    const today = new Date();
    today.setDate(today.getDate() + 1);
    today.setMonth(today.getMonth());
    return today; //this will return tomorrow's date
  });

  const [endDate, setEndDate] = useState("");

  const formattedStartDate = startDate
    ? startDate.toLocaleDateString("en-CA", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    })
    : "";
  const formattedDate = (date) => {
    return startDate.toLocaleDateString("en-CA", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    });
  };

  const formattedEndDate = endDate
    ? endDate.toLocaleDateString("en-CA", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    })
    : "";

  useEffect(() => {
    if (submitted && status !== undefined && message) {
      setLoading(false); // Stop loading indicator
      if (status) {
        Swal.fire({
          title: message,
          icon: "success",
          confirmButtonText: "Okay",
        });

        setTimeout(() => {
          if (selectedFile) {
            // Navigate to the campaign-valid-invalid page if the file is uploaded

            navigate(
              `/campaign-valid-invalid?Id=${uniqueId}&valid=${validCount}&invalid=${inValidCount}`
            );
          } else {
            // Navigate to the campaign-contest-report page if no file is uploaded
            navigate(`/campaign-contest-report`);
          }
        }, 2000);
      } else {
        Swal.fire({
          title: message,
          icon: "error",
          confirmButtonText: "Okay",
        });
      }
      setSubmitted(false);
    }
  }, [
    message,
    status,
    submitted,
    selectedFile,
    uniqueId,
    validCount,
    inValidCount,
  ]);

  useEffect(() => {
    const fetchToken = async () => {
      try {
        const response = await axios.get(
          `${appConfig.apiUrl}msCampUserUploadUniqueCode`,
          {
            headers: {
              "X-API-Key": appConfig.apiKey,
            },
          }
        );
        const uniqueId = response.data.data.uniqueId; // Assuming the token is in the response data
        setUniqueid(uniqueId);
      } catch (error) {
        // console.error("Error fetching token:", error);
      }
    };

    fetchToken();
  }, []);

  const handleuserSelectionChange = (e) => {
    // console.log("Selected value:", e.target.value);
    const { name, value } = e.target;
    // Reset all dropdown selections
    setUserroleSelected("All");
    setGroupnameSelected([]);
    setBrandnameSelected([]);
    setSubcategorySelected([]);
    setProductSelected([]);
    setBonusbenefitSelected("");
    resetToDefault();

    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value, // Dynamically update the formData based on the input's name attribute
    }));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData((prevFormData) => {
      const updatedFormData = {
        ...prevFormData,
        [name]: value,
      };
      return updatedFormData;
    });
  };

  const handleUserroleChange = (e) => {
    setUserroleSelected(e.target.value);
  };
  // console.log("this is userrole", userroleSelected);

  const handleGroupnameChange = (selectedIds) => {
    if (selectedIds.includes("allgroup")) {
      // "All" was chosen previously, now we're selecting other states as well, so removing 'All'
      if (selectedIds[0] === "allgroup" && selectedIds.length > 1) {
        const allIndex = selectedIds.indexOf("allgroup");
        selectedIds.splice(allIndex, 1); // Remove 'allgroup' from the array
      } else {
        selectedIds.splice(0, selectedIds.length); // Clear all items in the array
        selectedIds.push("allgroup"); // Keep only "allgroup" selected
        setGroupnameSelected(["All"]);
        return;
      }
    }
    const selectedGroupNames = selectedIds.map(
      (id) => groupList.find((group) => group.groupId === id)?.group
    );
    setGroupnameSelected(selectedGroupNames);
  };

  const handleBrandnameChange = (selectedIds) => {
    // console.log("this is brand selectedIds", selectedIds);
    if (selectedIds.includes("allbrand")) {
      // "All" was chosen previously, now we're selecting other states as well, so removing 'All'
      if (selectedIds[0] === "allbrand" && selectedIds.length > 1) {
        const allIndex = selectedIds.indexOf("allbrand");
        selectedIds.splice(allIndex, 1); // Remove 'allgroup' from the array
      } else {
        selectedIds.splice(0, selectedIds.length); // Clear all items in the array
        selectedIds.push("allbrand"); // Keep only "allgroup" selected
        setBrandnameSelected(["All"]);
        return;
      }
    }
    const selectedBrandNames = selectedIds.map(
      (id) => brandList.find((brand) => brand.brandId === id)?.brand
    );
    setBrandnameSelected(selectedBrandNames);
  };

  const handleSubcategoryChange = (selectedIds) => {
    if (selectedIds.includes("allsubcat")) {
      // "All" was chosen previously, now we're selecting other states as well, so removing 'All'
      if (selectedIds[0] === "allsubcat" && selectedIds.length > 1) {
        const allIndex = selectedIds.indexOf("allsubcat");
        selectedIds.splice(allIndex, 1); // Remove 'allsubcat' from the array
      } else {
        selectedIds.splice(0, selectedIds.length); // Clear all items in the array
        selectedIds.push("allsubcat"); // Keep only "allsubcat" selected
        setSubcategorySelected(["All"]);
        return;
      }
    }
    const selectedSubcategoryNames = selectedIds.map(
      (id) =>
        subCategoryList.find((subCategory) => subCategory.subId === id)
          ?.subCategory
    );
    setSubcategorySelected(selectedSubcategoryNames);
  };
  //   console.log("this is subcategorySelected", subcategorySelected);

  const handleProductChange = (selectedIds) => {
    if (selectedIds.includes("allproduct")) {
      // "All" was chosen previously, now we're selecting other states as well, so removing 'All'
      if (selectedIds[0] === "allproduct" && selectedIds.length > 1) {
        const allIndex = selectedIds.indexOf("allproduct");
        selectedIds.splice(allIndex, 1); // Remove 'allsubcat' from the array
      } else {
        selectedIds.splice(0, selectedIds.length); // Clear all items in the array
        selectedIds.push("allproduct"); // Keep only "allsubcat" selected
        setProductSelected(["All"]);
        return;
      }
    }
    const selectedProductNames = selectedIds.map(
      (id) =>
        productList.find((productName) => productName.pId === id)?.productName
    );
    setProductSelected(selectedProductNames);
  };

  const handleBonusbenefitChange = (e) => {
    setBonusbenefitSelected(e.target.value);
  };

  const fileInputRef = useRef(null);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
  };

  const handleNumericInput = (e) => {
    const input = e.target.value.replace(/[^0-9]/g, "");
    setFormData((prevState) => ({
      ...prevState,
      reward: input,
    }));
  };

  const handleStageChange = () => {
    setstageSelected();
  };

  const stageList = [
    { id: 1, name: "Open" },
    { id: 2, name: "Assigned to IT" },
    { id: 3, name: "Assigned to CCU" },
    { id: 4, name: "Assigned to AMC" },
    { id: 5, name: "Assigned back(CCU)" },
  ];

  const selectuserrole = [
    { id: 1, name: "All" },
    { id: 2, name: "Mechanic" },
    { id: 3, name: "Retailer" },
    { id: 4, name: "Customer" },
  ];

  const groupname = [
    { id: 1, name: "All" },
    { id: 2, name: "Mineral" },
  ];

  const brandname = [
    { id: 1, name: "All" },
    { id: 2, name: "All Gear" },
  ];

  const subcategory = [
    { id: 1, name: "All" },
    { id: 2, name: "Gear Oil" },
  ];

  const product = [
    { id: 1, name: "All" },
    { id: 2, name: "HD 85W140 (4 X 5 LTR)" },
  ];

  const bonusbenefit = [
    { id: 1, name: "All" },
    { id: 2, name: "Fixed" },
  ];

  useEffect(()=>{
    setSelectedDistrictNames(()=>{
      return  districtItemSelected.map((id) =>
        id === "alldistrict"
          ? "All"
          : districtList.find((district) => district.id === id)?.district
      )
    })
  },[districtItemSelected]);
  console.log("this is selectedPincodeNames",selectedPincodeNames)
  useEffect(()=>{
    setSelectedPincodeNames(()=>{
      return  pincodeItemSelected.map((id) =>
        id === "allpincode"
          ? "All"
          : pincodeList.find((pincode) => pincode.pinId === id)?.pincode
      )
    })
  },[pincodeItemSelected]);

  const handleDropdownChange = (e) => {
    const { value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      conditionCamp: value, // Update conditionCamp field
    }));
  };

  const handleAddContainer = (e) => {
    if (e) e.preventDefault(); // Prevent default form submission behavior
    const id = Date.now(); // Unique ID for the container
    setDynamicVariables((prev) => [...prev, { id }]);

    // Initialize fields for the new container in the formData state
    setDynamicData((prev) => ({
      ...prev,
      [`bonuspoints_${id}`]: "",
      [`volfrom_${id}`]: "",
      [`volto_${id}`]: "",
      [`scanfrom_${id}`]: "",
      [`scanto_${id}`]: "",
      [`bonusbenefits_${id}`]: "",
      [`conditionCamp_${id}`]: "",
    }));
  };

  const handleRemoveContainer = (id) => {
    setDynamicVariables((prev) =>
      prev.filter((container) => container.id !== id)
    );

    // Remove the associated fields for this container
    setDynamicData((prev) => {
      const updated = { ...prev };
      Object.keys(updated).forEach((key) => {
        if (key.endsWith(`_${id}`)) {
          delete updated[key];
        }
      });
      return updated;
    });
  };

  const handleInputChange = (id, field, value) => {
    setDynamicData((prev) => ({
      ...prev,
      [`${field}_${id}`]: value,
    }));
  };

  const groupFormData = () => {
    const grouped = {};
    Object.keys(dynamicData).forEach((key) => {
      const [field] = key.split("_"); // Extract base field name
      if (!grouped[field]) {
        grouped[field] = [];
      }
      grouped[field].push(dynamicData[key]);
    });
    setGroupedData(grouped);
  };
  useEffect(() => {
    handleAddContainer(); // Initialize with one container on mount
  }, []);
  useEffect(() => {
    groupFormData(); // Regroup data whenever formData changes
  }, [dynamicData]);
  // console.log("this is formdata below dynamicData", dynamicData);
  // console.log("this is dynamicVariables", dynamicVariables);
  // console.log("this is groupedData", groupedData);

  //   const resetFields = () => {
  //     setFormData({
  //       campaignType: "",
  //       campaignName: "",
  //       benefitType: "",
  //       userSelection: "",
  //       reward: "",
  //       volumeFrom: "",
  //       volumeTo: "",
  //       scanFrom: "",
  //       scanTo: "",
  //       userPreference: "",
  //       type: "",
  //       limit: "",
  //       conditionCamp: "",
  //     });
  //     setStartDate(new Date());
  //     setEndDate("");
  //     setSelectedFile(null);
  //     setUniqueid("");
  //     setstageSelected("");
  //     setUserroleSelected("All");
  //     setGroupnameSelected("");
  //     setBrandnameSelected("");
  //     setSubcategorySelected("");
  //     setProductSelected("");
  //     setBonusbenefitSelected("");
  //     setStateItemSelected([]);
  //     setDistrictItemSelected([]);
  //   };

  const handleSubmit = async () => {
    const form_Data = new FormData();
    console.log("thi si appConfig url", appConfig)

    form_Data.append("msLoggedUserId", msLoggedUserId);
    form_Data.append("msLoggedRoleId", msLoggedRoleId);
    form_Data.append("uniqueId", uniqueId);
    form_Data.append("token", token);
    form_Data.append("campType", formData.campaignType);
    form_Data.append("contestName", formData.campaignName);
    form_Data.append("userSel", formData.userSelection);
    form_Data.append("userFile", selectedFile);
    form_Data.append("benefitType", formData.benefitType);
    form_Data.append("startDate", formattedStartDate);
    form_Data.append("endDate", formattedEndDate);
    form_Data.append("userRole", userroleSelected);
    form_Data.append("userPreference", formData.userPreference); // userPreference field
    form_Data.append("runType", formData.runType); // type field
    form_Data.append("limit", formData.limit); // limit field
    form_Data.append("bonusRemarks", formData.bonusRemarks);

    groupnameSelected.forEach((group) => {
      form_Data.append("groupName[]", group);
    });

    brandnameSelected.forEach((brand) => {
      form_Data.append("brandName[]", brand);
    });

    subcategorySelected.forEach((subCategory) => {
      form_Data.append("subCatName[]", subCategory);
    });

    productSelected.forEach((productName) => {
      form_Data.append("productName[]", productName);
    });

    stateItemSelected.forEach((state) => {
      form_Data.append("state[]", state);
    });
    selectedDistrictNames.forEach((district) => {
      form_Data.append("district[]", district);
    });
    selectedPincodeNames.forEach((pincode)=>{
      form_Data.append("pincode[]", pincode)
    })
    const customMapping = {
      bonusbenefits: "bonusbenefit",
      bonuspoints: "reward",
      conditionCamp: "conditionCamp",
      scanfrom: "qtyFrom",
      scanto: "qtyTo",
      volfrom: "volFrom",
      volto: "volTo",
    };
    Object.keys(groupedData).forEach((key) => {
      // Get the custom name or default to the original key
      const customKey = customMapping[key] || key;

      // Access the array of values for the current key
      const values = groupedData[key];

      // Append each value to formData using the custom format
      values.forEach((value) => {
        form_Data.append(`${customKey}[]`, value);
      });
    });

    try {
      // Assuming dispatching an action to submit the data
      await dispatch(getcampaignPageData(form_Data));
      setSubmitted(true);
      // resetFields();
    } catch (error) {
    } finally {
    }
  };
  // console.log("this is form", formData);
  return (
    <>
      <h2 className="text-capitalize fs-5 fst-italic mt-5 ml-4">Create Campaigns/Scheme</h2>
      <div
        className="container mt-5 form-div"
        style={{ minHeight: "81.8vh", width: "1000px", border: "solid", padding: "40px" }}
      >
        <Form>
          {/* Campaign Type */}
          <Form.Group as={Row} className="mb-3 align-items-center">
            <Form.Label column sm={4} className="label-camp">
              Campaign Type {<span className="required-symbol">*</span>}
            </Form.Label>
            <Col sm={8}>
              <Form.Check
                inline
                label="Campaign"
                name="campaignType"
                type="radio"
                id="Campaign"
                value="Campaign"
                onChange={handleChange}
                checked={formData.campaignType === "Campaign"}
              />
              <Form.Check
                inline
                label="Lucky Draw"
                name="campaignType"
                type="radio"
                id="Lucky Draw"
                value="LuckyDraw"
                onChange={handleChange}
                checked={formData.campaignType === "LuckyDraw"}
              />
            </Col>
          </Form.Group>

          {/* Campaign Name */}
          <Form.Group as={Row} className="mb-3">
            <Form.Label column sm={4} className="label-camp">
              Campaign / Scheme Name: {<span className="required-symbol">*</span>}
            </Form.Label>
            <Col sm={8}>
              <Form.Control
                type="text"
                placeholder="Enter Campaign / Scheme Name"
                name="campaignName" // matches the key in formData
                value={formData.campaignName} // ensure it's a controlled input
                onChange={handleChange} // update formData when the value changes
              />
            </Col>
          </Form.Group>

          <Form.Group as={Row} className="mb-3">
            <Form.Label column sm={4} className="label-camp">
             App Bonus Points Remarks: {<span className="required-symbol">*</span>}
            </Form.Label>
            <Col sm={8}>
              <Form.Control
                type="text"
                placeholder="Enter Bonus Point Remarks"
                name="bonusRemarks" // matches the key in formData
                value={formData.bonusRemarks} // ensure it's a controlled input
                onChange={handleChange} // update formData when the value changes
              />
            </Col>
          </Form.Group>


          {/* Benefit Type */}
          <Form.Group as={Row} className="mb-3 align-items-center">
            <Form.Label column sm={4} className="label-camp">
              Benefit Type {<span className="required-symbol">*</span>}
            </Form.Label>
            <Col sm={8}>
              <Form.Check
                inline
                label="Instant"
                name="benefitType" // This matches the state key
                type="radio"
                id="instant"
                value="Instant" // Value when selected
                checked // Ensure it's checked if selected
              />
              {/* <Form.Check
              inline
              label="Duration"
              name="benefitType" // This matches the state key
              type="radio"
              id="durationBased"
              value="Duration" // Value when selected
              checked={formData.benefitType === "Duration"} // Ensure it's checked if selected
              onChange={handleChange} // Update the state when clicked
            /> */}
            </Col>
          </Form.Group>

          {/* Start Date and End Date */}
          <Row className="mb-3">
            <Col>
              <Common.CustomDatePicker
                label={<p className="label-camp">Scheme Start Date <span className="required-symbol">*</span></p>}
                dateFormat={"dd-MM-yyyy"}
                selected={startDate}
                onChange={(date) => setStartDate(date)}
                placeholderText="dd-yy-yyyy"
                minVal={startDate}
              />
            </Col>
            <Col>
              <Common.CustomDatePicker
                label={<p className="label-camp">Scheme End Date <span className="required-symbol">*</span></p>}
                dateFormat={"dd-MM-yyyy"}
                selected={endDate}
                onChange={(date) => setEndDate(date)}
                placeholderText="dd-yy-yyyy"
                minVal={startDate}
              />
            </Col>
          </Row>

          {/* User Selection */}
          <Form.Group as={Row} className="mb-3 align-items-center">
            <Form.Label column sm={4} className="label-camp">
              User Selection {<span className="required-symbol">*</span>}
            </Form.Label>
            <Col sm={8}>
              <Form.Check
                inline
                label="Select User"
                name="userSelection"
                type="radio"
                id="selectUser"
                onChange={handleuserSelectionChange}
                value="User"
                className=""
                checked={formData.userSelection === "User"}
              />
              <Form.Check
                inline
                label="Upload User"
                name="userSelection"
                type="radio"
                id="Upload"
                value="Upload"
                onChange={handleuserSelectionChange}
                checked={formData.userSelection === "Upload"}
              />
            </Col>
          </Form.Group>

          {formData.userSelection === "Upload" && (
            <Form.Group as={Row} className="mb-4">
              <Form.Label column sm={4} className="label-camp">
                Select File {<span className="required-symbol">*</span>}
              </Form.Label>
              <Col sm={8}>
                <Common.FileInput
                  onChange={handleFileChange}
                  inputRef={fileInputRef}
                />
                <div className="col-sm-12 mt-3">
                  <a
                    href={
                      `${appConfig.apiUrl}uploads/samples/Sample_Contest_Users.xlsx`
                    }
                    className="text-dark"
                  >
                    Sample File
                  </a>
                </div>
              </Col>
            </Form.Group>
          )}

          {formData.userSelection !== "Upload" && (
            <>
              {/* Dropdowns for user selection */}
              <Form.Group as={Row} className="mb-4">
                <Form.Label column sm={4} className="label-camp">
                  Select User {<span className="required-symbol">*</span>}
                </Form.Label>
                <Col sm={8}>
                  <Common.SelectInput
                    options={selectuserrole}
                    defaultValue="All"
                    labelKey="name"
                    valueKey="id"
                    value={userroleSelected}
                    className="bg-white"
                    onChange={handleUserroleChange}
                    disabled={formData.userSelection === "Upload"}
                  />
                </Col>
              </Form.Group>

              <Form.Group as={Row} className="mb-4">
                <Col sm={4}>
                  <label className={`${styles.label} label-camp`}>
                    Select State {<span className="required-symbol">*</span>}
                  </label>
                </Col>
                <Col sm={8}>
                  <Space style={{ width: "100%" }} direction="vertical">
                    <Select
                      mode="multiple"
                      allowClear
                      style={{ width: "100%" }}
                      placeholder="Please select"
                      onChange={handleStateChange}
                      options={stateOptionsWithIds} 
                      value={selectedStateIds}
                      defaultValue={[stateOptionsWithIds[0]?.value]} // Default selection
                      size="large"
                      showSearch
                      filterOption={(input, option) => {
                        return option.label
                          .toLowerCase()
                          .includes(input.toLowerCase()); // Customize search filter
                      }}
                    />
                  </Space>
                </Col>
              </Form.Group>
              

              <Form.Group as={Row} className="mb-4">
                <Col sm={4}>
                  <label className="label-camp">Select District {<span className="required-symbol">*</span>}</label>
                </Col>
                <Col sm={8}>
                  <Space
                    style={{ width: "100%", marginTop: "-5px" }}
                    direction="vertical"
                  >
                    <Select
                      mode="multiple"
                      allowClear
                      style={{ width: "100%" }}
                      placeholder="Please select"
                      onChange={handleDistrictChange}
                      options={[
                        { label: "All", value: "alldistrict" },
                        ...districtList.map((item) => ({
                          label: item.district,
                          value: item.id,
                        })),
                      ]}
                      value={districtItemSelected}
                      size="large"
                      showSearch
                      filterOption={(input, option) => {
                        return option.label
                          .toLowerCase()
                          .includes(input.toLowerCase()); // Customize search filter
                      }}
                    />
                  </Space>
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="mb-4">
                <Col sm={4}>
                  <label className="label-camp">Select Pincode {<span className="required-symbol">*</span>}</label>
                </Col>
                <Col sm={8}>
                  <Space
                    style={{ width: "100%", marginTop: "-5px" }}
                    direction="vertical"
                  >
                    <Select
                      mode="multiple"
                      // allowClear
                      style={{ width: "100%" }}
                      placeholder="Please select"
                      onChange={handlePincodeChange}  
                      options={[
                        { label: "All", value: "allpincode" },
                        ...pincodeList.map((item) => ({
                          label: item.pincode,
                          value: item.pinId,
                        })),
                      ]}
                      value={pincodeItemSelected}
                      size="large"
                      // showSearch
                      // filterOption={(input, option) => {
                      //   return option.label
                      //     .toLowerCase()
                      //     .includes(input.toLowerCase()); // Customize search filter
                      // }}
                    />
                  </Space>
                </Col>
              </Form.Group>
            </>
          )}

          <Form.Group as={Row} className="mb-4">
            <Form.Label column sm={4} className="label-camp">
              Group Name {<span className="required-symbol">*</span>}
            </Form.Label>
            <Col sm={8}>
              <Select
                mode="multiple"
                allowClear
                style={{ width: "100%" }}
                placeholder="Please select"
                options={groupMasterListWithIds}
                value={selectedGroupMasterList}
                onChange={handleGroupnameChange}
                size="large"
                showSearch
                filterOption={(input, option) => {
                  return option.label.toLowerCase().includes(input.toLowerCase()); // Customize search filter
                }}
              />
            </Col>
          </Form.Group>

          <Form.Group as={Row} className="mb-4">
            <Form.Label column sm={4} className="label-camp">
              Brand Name {<span className="required-symbol">*</span>}
            </Form.Label>
            <Col sm={8}>
              <Select
                mode="multiple"
                allowClear
                style={{ width: "100%" }}
                placeholder="Please select"
                options={brandMasterListWithIds}
                value={selectedBrandMasterList}
                onChange={handleBrandnameChange}
                size="large"
                showSearch
                filterOption={(input, option) => {
                  return option.label.toLowerCase().includes(input.toLowerCase()); // Customize search filter
                }}
              />
            </Col>
          </Form.Group>

          <Form.Group as={Row} className="mb-4">
            <Form.Label column sm={4} className="label-camp">
              Subcategory Name {<span className="required-symbol">*</span>}
            </Form.Label>
            <Col sm={8}>
              <Select
                mode="multiple"
                allowClear
                style={{ width: "100%" }}
                placeholder="Please select"
                options={subCategoryMasterListWithIds}
                value={selectedSubcategoryMasterList}
                onChange={handleSubcategoryChange}
                size="large"
                showSearch
                filterOption={(input, option) => {
                  return option.label.toLowerCase().includes(input.toLowerCase()); // Customize search filter
                }}
              />
              {/* <Common.SelectInput
                            options={subCategoryList}
                            labelKey="subCategory"
                            valueKey="subId"
                            value={subcategorySelected}
                            onChange={handleSubcategoryChange}
                        /> */}
            </Col>
          </Form.Group>

          <Form.Group as={Row} className="mb-4">
            <Form.Label column sm={4} className="label-camp">
              Product Name {<span className="required-symbol">*</span>} 
            </Form.Label>
            <Col sm={8}>
              <Select
                mode="multiple"
                allowClear
                style={{ width: "100%" }}
                placeholder="Please select"
                options={productMasterListWithIds}
                value={selectedproductMasterList}
                onChange={handleProductChange}
                size="large"
                showSearch
                filterOption={(input, option) => {
                  return option.label.toLowerCase().includes(input.toLowerCase()); // Customize search filter
                }}
              />
              {/* <Common.SelectInput
                            options={productList}
                            labelKey="productName"
                            valueKey="pId"
                            value={productSelected}
                            onChange={handleProductChange}
                        /> */}
            </Col>
          </Form.Group>

          {dynamicVariables.map((container, index) => (
            <div className="flex-row " key={container.id}>
              <Container
                className="p-3 mb-5 width-90"
                style={{ border: "1px solid black" }}
              >
                <Form.Group as={Row} className="mb-4">
                  <Form.Label column sm={4} className="label-camp">
                    Bonus Benefit {<span className="required-symbol">*</span>}
                  </Form.Label>
                  <Col sm={8}>
                    <Common.SelectInput
                      options={benefitList}
                      labelKey="name"
                      valueKey="id"
                      value={dynamicData[`bonusbenefits_${container.id}`] || ""}
                      onChange={(e) =>
                        handleInputChange(
                          container.id,
                          "bonusbenefits",
                          e.target.value
                        )
                      }
                    />
                  </Col>
                </Form.Group>

                <Form.Group as={Row} className="mb-3">
                  <Form.Label column sm={4} className="label-camp">
                    Reward Bonus Points {dynamicData[`bonusbenefits_${container.id}`] === "Percentage" ? "%" : ""} {<span className="required-symbol">*</span>}
                  </Form.Label>
                  <Col sm={8}>
                    <Form.Control
                      type="text"
                      placeholder={`Enter Reward ${dynamicData[`bonusbenefits_${container.id}`] === "Percentage" ? "percentage" : "points"}`}
                      name="bonuspoints"
                      value={dynamicData[`bonuspoints_${container.id}`] || ""}
                      onChange={(e) =>
                        handleInputChange(
                          container.id,
                          "bonuspoints",
                          e.target.value
                        )
                      }
                    />
                  </Col>
                </Form.Group>
                <Form>
                  {/* First Row: Bonus Benefit Type and Volume Section */}
                  <Row className="mb-3 align-items-center">
                    <Col xs={2} className="apply-mt-text">
                      <Form.Label>Transaction Points</Form.Label>
                    </Col>
                    <Col xs={2} className="apply-mt">
                      <Form.Select className="size-font">
                        <option>Greater than or equal to</option>
                      </Form.Select>
                    </Col>
                    <Col xs={2} className="apply-mt ">
                      <Form.Control
                        type="text"
                        placeholder="From"
                        className="size-font"
                        value={dynamicData[`volfrom_${container.id}`] || ""}
                        name="volfrom"
                        onChange={(e) =>
                          handleInputChange(
                            container.id,
                            "volfrom",
                            e.target.value
                          )
                        }
                      />
                    </Col>
                    <Col xs={2} className="apply-mt">
                      <Form.Select className="size-font">
                        <option>Less than or equal to</option>
                      </Form.Select>
                    </Col>
                    <Col xs={2} className="mb-0 apply-mt">
                      <Form.Control
                        type="text"
                        placeholder="To"
                        className="size-font"
                        value={dynamicData[`volto_${container.id}`] || ""}
                        name="volTo"
                        onChange={(e) =>
                          handleInputChange(container.id, "volto", e.target.value)
                        }
                      />
                    </Col>
                  </Row>

                  <Row>
                    <Col xs={1}></Col>
                    <Col xs={2}></Col>
                    <Col xs={2}></Col>
                    <Col xs={2} className="apply-mt">
                      <Form.Select
                        className="size-font"
                        value={dynamicData[`conditionCamp_${container.id}`] || ""}
                        onChange={(e) =>
                          handleInputChange(
                            container.id,
                            "conditionCamp",
                            e.target.value
                          )
                        }
                      >
                        <option value="">None selected</option>
                        <option value="AND">AND</option>
                        <option value="OR">OR</option>
                      </Form.Select>
                    </Col>
                    <Col xs={2}>
                      <Form.Label className="apply-mt-text">(and/or)</Form.Label>
                    </Col>
                    <Col xs={2}></Col>
                  </Row>
                  {/* Second Row: Scan Section */}
                  <Row className="mb-3 align-items-center">
                    <Col xs={2} className="apply-mt-text">
                      <Form.Label>Scan</Form.Label>
                    </Col>
                    <Col xs={2} className="apply-mt">
                      <Form.Select className="size-font">
                        <option>Greater than or equal to</option>
                      </Form.Select>
                    </Col>
                    <Col xs={2} className="apply-mt ">
                      <Form.Control
                        type="text"
                        placeholder="From"
                        className="size-font"
                        value={dynamicData[`scanfrom_${container.id}`] || ""}
                        name="scanFrom"
                        onChange={(e) =>
                          handleInputChange(
                            container.id,
                            "scanfrom",
                            e.target.value
                          )
                        }
                      />
                    </Col>
                    <Col xs={2} className="apply-mt">
                      <Form.Select className="size-font">
                        <option>Less than or equal to</option>
                      </Form.Select>
                    </Col>
                    <Col xs={2} className="mb-0 apply-mt">
                      <Form.Control
                        type="text"
                        placeholder="To"
                        className="size-font"
                        name="scanTo"
                        value={dynamicData[`scanto_${container.id}`] || ""}
                        onChange={(e) =>
                          handleInputChange(
                            container.id,
                            "scanto",
                            e.target.value
                          )
                        }
                      />
                    </Col>
                  </Row>
                </Form>
              </Container>
              {index === 0 ? (
                <button
                  type="button"
                  onClick={handleAddContainer}
                  className="addmorebutton"
                >
                  Add More
                </button>
              ) : (
                <button
                  type="button"
                  onClick={() => handleRemoveContainer(container.id)}
                  className="removecontbutton"
                >
                  Remove
                </button>
              )}
            </div>
          ))}

          {formData.campaignType === "LuckyDraw" && (
            <Container
              className="p-3"
              style={{ border: "1px solid #ccc", width: "100%" }}
            >
              <Form>
                <Form.Group as={Row}>
                  <Col sm={4}>
                    <Form.Label>
                      <strong>User Preference  {<span className="required-symbol">*</span>}</strong>
                    </Form.Label>
                  </Col>
                  <Col sm={4}>
                    <Form.Check
                      type="radio"
                      label="First"
                      name="userPreference"
                      value="First" // Add value here to match the state
                      checked={formData.userPreference === "First"} // Make sure it reflects the current state
                      inline
                      onChange={handleChange}
                    />
                    <Form.Check
                      type="radio"
                      label="Random"
                      name="userPreference"
                      value="Random" // Add value here to match the state
                      checked={formData.userPreference === "Random"} // Make sure it reflects the current state
                      inline
                      onChange={handleChange}
                    />
                  </Col>
                </Form.Group>

                <Form.Group as={Row}>
                  <Col sm={4}>
                    <Form.Label>
                      <strong>Run type  {<span className="required-symbol">*</span>}</strong>
                    </Form.Label>
                  </Col>
                  <Col sm={4}>
                    <Form.Check
                      type="radio"
                      label="Daily"
                      name="runType"
                      value="Daily" // Add value here to match the state
                      checked={formData.runType === "Daily"} // Make sure it reflects the current state
                      inline
                      onChange={handleChange}
                    />
                    <Form.Check
                      type="radio"
                      label="One time"
                      name="runType"
                      value="One time" // Add value here to match the state
                      checked={formData.runType === "One time"} // Make sure it reflects the current state
                      inline
                      onChange={handleChange}
                    />
                    <span className="ml-2">(for limit)</span>
                  </Col>
                </Form.Group>

                <Form.Group as={Row}>
                  <Col sm={4}>
                    <Form.Label>
                      <strong>Limit (No. of Users) {<span className="required-symbol">*</span>}</strong>
                    </Form.Label>
                  </Col>
                  <Col sm={4}>
                    <Form.Control
                      type="text"
                      placeholder="Limit"
                      name="limit"
                      value={formData.limit} // Bind value to state
                      onChange={handleChange}
                    />
                  </Col>
                </Form.Group>
              </Form>
            </Container>
          )}

          <Row className="mb-3 mt-4 justify-content-center">
            <Button className="submit_button" onClick={handleSubmit}>
              Submit
            </Button>
          </Row>
        </Form>
      </div></>
  );
};

export default CampaignBasedContestForm;
