import React from "react";
import styles from "./SelectInput.module.css";

const SelectInput = ({
  label,
  options,
  value,
  onChange,
  labelKey,
  valueKey,
  defaultValue,
  className,
}) => {
  return (
    <div className={styles.selectInputContainer}>
      <label className={styles.label}>{label}</label>
      <select value={value} onChange={onChange} className={styles.selectInput}>
        {
          defaultValue ? "" : <option value="" >Choose an option</option>
        }
        {options &&
          options.map((option) => (
            <option key={option[valueKey]} value={option[labelKey]} >
              {option[labelKey]}
            </option>
          ))}
      </select>
    </div>
  );
};
export default SelectInput;
