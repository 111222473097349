// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CustomDatePicker_label__fHjuf {
  display: block;
  margin-bottom: 6px;
}

/* .react-datepicker-wrapper {
  display: block !important;
}

.datePicker {
  width: 100%;
  display: block !important;
} */

.CustomDatePicker_csDatePicker__GgOq4 {
  display: block !important;
  background-color: white;
}

`, "",{"version":3,"sources":["webpack://./src/component/common/DatePicker/CustomDatePicker.module.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,kBAAkB;AACpB;;AAEA;;;;;;;GAOG;;AAEH;EACE,yBAAyB;EACzB,uBAAuB;AACzB","sourcesContent":[".label {\n  display: block;\n  margin-bottom: 6px;\n}\n\n/* .react-datepicker-wrapper {\n  display: block !important;\n}\n\n.datePicker {\n  width: 100%;\n  display: block !important;\n} */\n\n.csDatePicker {\n  display: block !important;\n  background-color: white;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"label": `CustomDatePicker_label__fHjuf`,
	"csDatePicker": `CustomDatePicker_csDatePicker__GgOq4`
};
export default ___CSS_LOADER_EXPORT___;
