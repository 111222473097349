// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modalheader{
    width: 100%;
}

.modalheader-div{
    display: flex;
    justify-content: space-between;
    width: full;
    margin-right: 10px;
}
.download-button{
    background-color: #198754;
    color: whitesmoke;
    padding: 3px;
    padding-right: 5px;
    padding-left: 5px;
    border-radius: 4px;
    font-weight: 300;
    font-size: medium;
}
.download-button:hover{
    cursor: pointer;
    background-color: #4db183;
}
`, "",{"version":3,"sources":["webpack://./src/component/common/Modal/ModalStyles.css"],"names":[],"mappings":"AAAA;IACI,WAAW;AACf;;AAEA;IACI,aAAa;IACb,8BAA8B;IAC9B,WAAW;IACX,kBAAkB;AACtB;AACA;IACI,yBAAyB;IACzB,iBAAiB;IACjB,YAAY;IACZ,kBAAkB;IAClB,iBAAiB;IACjB,kBAAkB;IAClB,gBAAgB;IAChB,iBAAiB;AACrB;AACA;IACI,eAAe;IACf,yBAAyB;AAC7B","sourcesContent":[".modalheader{\n    width: 100%;\n}\n\n.modalheader-div{\n    display: flex;\n    justify-content: space-between;\n    width: full;\n    margin-right: 10px;\n}\n.download-button{\n    background-color: #198754;\n    color: whitesmoke;\n    padding: 3px;\n    padding-right: 5px;\n    padding-left: 5px;\n    border-radius: 4px;\n    font-weight: 300;\n    font-size: medium;\n}\n.download-button:hover{\n    cursor: pointer;\n    background-color: #4db183;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
