import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import appConfig from "../AppConfig";

const initialState = {
  data: [],
  loading: false,
  error: "",
};

// getDashboardData
export const getProductMasterNewData = createAsyncThunk(
  "getProductMasterNewData",
  async (msLoggedRoleId, { rejectWithValue }) => {
    console.log("subcategory first");
    const urls = `${appConfig.apiUrl}msProductMasterNew?msLoggedRoleId=${msLoggedRoleId}`;
    console.log("subcategory urlsssss",urls );
    const headers = {
      "X-API-Key": appConfig.apiKey,
    };
    const response = await axios.get(urls, {
      headers: headers,
    });
    try {
      const result = await response.data.data;
      console.log("subcategory NEWWWWWWWWW",result );
      return result;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);


export const productMasterNew = createSlice({
  name: "productMasterNew",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getProductMasterNewData.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getProductMasterNewData.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload;
      state.error = "";
    });
    builder.addCase(getProductMasterNewData.rejected, (state, action) => {
      state.loading = false;
      state.data = [];
      state.error = action.error.message;
    });
  },
});

export default productMasterNew.reducer;
