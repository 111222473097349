import React, { useState, useEffect } from "react";
import { Common } from "../../../component/common/common";
import { getviewCampaignLuckyDrawData } from "../../../features/viewCampaignLuckDrawSchemeSlice";
import { useDispatch, useSelector } from "react-redux";
import { getDetailRowCampaignScheme } from "../../../features/DetailRowCampaignSchemeSlice";
import { getCampaignUploadUserDetails } from "../../../features/CampaignUploadUserDetailsSlice";
import Modal_Component from "../../../component/common/Modal/Modal";
import "../../../component/common/TableComponent/CustomTable.module.css";
import { getupdateStatusCampaign } from "../../../features/UpdateStatusCampaignSlice";
import Campaign_Filters from "../../../component/common/Filters/campaignfilters";
import { downloadUser } from "../../../features/downloadUserDetailsSlice";

import "./campaignreports.css";

const columns = [
  // "Scheme ID",
  "Campaign Type",
  "Contest Name",
  "Benefit Type",
  "User Selection Type",
  "Start Date",
  "End Date",
  "User Role",
  "State",
  "District",
  "Pincode",
  "Group Category",
  "Brand",
  "Sub Category",
  "Product",
  "User Preference",
  "Contest Run Type",
  "User Limit",
  "Created Date",
  "Status",
  "Scheme Segment Details",
  "User Details",
  "Actions",
];

const userdetailscolumns = [
  "S.No.",
  "Username",
  "Mobile",
  "Scheme Created Date",
  "Role",
  "Contest Name",
  "Contest Type",
];

function CampaignReport() {
  const msLoggedUserId = localStorage.getItem("userId");
  const msLoggedRoleId = localStorage.getItem("roleId");
  const token = localStorage.getItem("logintoken");
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const [modalBody, setModalBody] = useState(null);
  const [modalTitle, setModalTitle] = useState("");
  const [selectedSchemeId, setSelectedSchemeId] = useState(null);
  const [statusData, setStatusData] = useState({});
  const { data, totalRecords, message } = useSelector(
    (state) => state.viewCampaignLuckyDrawDetails.data
  );
  console.log("data", data);

  const [tableData, setTableData] = useState([]);

  const [inactiveStatusMap, setInactiveStatusMap] = useState({});

  const { status } = useSelector((state) => state.updateStatusDetails);

  console.log("status", status);

  const [stateItemSelected, setStateItemSelected] = useState("");
  const [districtItemSelected, setDistrictItemSelected] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [actionType, setActionType] = useState(null);
  const [statusfilter, setStatusFilter] = useState("");
  const [campaignTypeFilter, setCampaignTypeFilter] = useState("");
  const [downloadingMap, setDownloadingMap] = useState("");

  const handleStatusFilterChange = (e) => {
    setStatusFilter(e.target.value);
  };
  const handleCampaignTypeChange = (e) => {
    setCampaignTypeFilter(e.target.value);
  };

  const formatDate = (date) => {
    if (!date) return "";
    const d = new Date(date);
    const year = d.getFullYear();
    const month = String(d.getMonth() + 1).padStart(2, "0");
    const day = String(d.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`; // Return in YYYY-MM-DD format
  };

  const formattedStartDate = startDate ? formatDate(startDate) : "";
  const formattedEndDate = endDate ? formatDate(endDate) : "";

  const itemsPerPage = 10;
  // const totalRecords = data ? data.length : 0;
  const totalPages = Math.ceil(totalRecords / itemsPerPage);
  console.log("totalPages", totalPages);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  console.log("endIndex", endIndex);

  const modalItemsPerPage = 10;
  const [modalCurrentPage, setModalCurrentPage] = useState(1);
  const modalStartIndex = (modalCurrentPage - 1) * modalItemsPerPage;
  const modalEndIndex = modalStartIndex + modalItemsPerPage;

  const Status_Options = [
    { label: "Active", value: "active" },
    { label: "InActive", value: "Inactive" },
  ];
  const campaignTypeOptions = [
    { label: "Campaign", value: "campaign" },
    { label: "LuckyDraw", value: "luckydraw" },
  ];

  useEffect(() => {
    if (data) {
      data.map((item) => {
        dayFirstDateFormat(item.created_date);
      });
    }
  }, [data]);
  useEffect(() => {
    if (formattedStartDate && formattedEndDate) {
      dispatch(
        getviewCampaignLuckyDrawData({
          formattedStartDate,
          formattedEndDate,
          startIndex,
          msLoggedUserId,
          msLoggedRoleId,
          token,
        })
      );
    }
  }, [
    dispatch,
    currentPage,
    formattedStartDate,
    formattedEndDate,
    msLoggedUserId,
    msLoggedRoleId,
    token,
  ]);

  useEffect(() => {
    dispatch(
      getviewCampaignLuckyDrawData({
        formattedStartDate,
        formattedEndDate,
        startIndex,
        msLoggedUserId,
        msLoggedRoleId,
        token,
      })
    );
  }, [statusData, startIndex, inactiveStatusMap]);

  function dayFirstDateFormat(dateString, showTime) {
    const date = new Date(dateString);
    const formattedDate = `${String(date.getDate()).padStart(2, "0")}-${String(
      date.getMonth() + 1
    ).padStart(2, "0")}-${date.getFullYear()}`;

    if (showTime === false) {
      return <div>{formattedDate}</div>;
    } else {
      // Format the date as dd/mm/yyyy hh:mm:ss
      const formattedDateAndTime = `${formattedDate} ${String(
        date.getHours()
      ).padStart(2, "0")}:${String(date.getMinutes()).padStart(
        2,
        "0"
      )}:${String(date.getSeconds()).padStart(2, "0")}`;
      console.log("formateed date", formattedDate);
      return <div>{formattedDateAndTime}</div>;
    }
  }
  useEffect(() => {
    console.log("this is actionType", actionType);
    if (selectedSchemeId && actionType === "getUserDetails") {
      const modalStartIndex = (modalCurrentPage - 1) * modalItemsPerPage;

      dispatch(
        getCampaignUploadUserDetails({
          msLoggedUserId,
          msLoggedRoleId,
          token,
          schemeId: selectedSchemeId,
          formattedStartDate,
          formattedEndDate,
          startIndex: modalStartIndex,
        })
      )
        .then((response) => {
          const userDetails = response.payload.data;
          const totalRecords = response.payload.totalRecords;
          //now here updating date format
          console.log("this is userDetails", userDetails[0].schemeDate);

          const usertabledata = userDetails.map((user, index) => ({
            "S.No.": modalStartIndex + index + 1,
            Username: user.userName,
            Mobile: user.userMobile,
            "Scheme Created Date": dayFirstDateFormat(user.schemeDate, true),
            Role: user.roleName,
            "Contest Name": user.contestName,
            "Contest Type": user.contestType,
          }));

          const modalTotalPages = totalRecords
            ? Math.ceil(totalRecords / modalItemsPerPage)
            : 0;

          setModalBody(
            <Common.CustomTable
              columns={userdetailscolumns}
              data={usertabledata}
              message={userDetails.length === 0 ? "Data not found" : ""}
              totalRecords={userDetails.length}
              itemsPerPage={modalItemsPerPage}
              currentPage={modalCurrentPage}
              setCurrentPage={setModalCurrentPage}
              totalPages={modalTotalPages}
              startIndex={modalStartIndex}
              endIndex={modalEndIndex}
            />
          );
        })
        .catch((error) => {
          console.error("Error fetching user details:", error);
          setModalBody(<p>Data Not Found</p>);
        });
    }
  }, [
    selectedSchemeId,
    modalCurrentPage,
    msLoggedUserId,
    msLoggedRoleId,
    token,
    formattedStartDate,
    formattedEndDate,
    dispatch,
  ]);

  const handleDownload = () => {
    const msLoggedUserId = localStorage.getItem("userId");
    const msLoggedRoleId = localStorage.getItem("roleId");
    const token = localStorage.getItem("logintoken");
    dispatch(
      downloadUser({
        fromDate: "",
        toDate: "",
        token,
        msLoggedRoleId,
        msLoggedUserId,
        schemeId: selectedSchemeId,
      })
    )
      .then((response) => {
        console.log("entered the memberlist response");
        const currentDate = new Date();
        const timestamp = currentDate.getTime();
        const formattedDate = currentDate.toISOString().slice(0, 10);
        const suggestedFileName = `user_details_${formattedDate}_${timestamp}.csv`;
        const blob = new Blob([response.payload], { type: "text/csv" });

        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = suggestedFileName;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        setDownloadingMap(false);
      })
      .catch((error) => {
        console.error("Download error:", error);

        setDownloadingMap(false);
      });
  };

  const handleViewDetails = (schemeId) => {
    //this views campaign details

    setSelectedSchemeId(schemeId); // Make sure this is setting the correct scheme ID
    console.log("Selected Scheme ID:", schemeId); // Debugging log
    setActionType("getCampDetails"); // Set the action type

    dispatch(
      getDetailRowCampaignScheme({
        msLoggedUserId,
        msLoggedRoleId,
        token,
        schemeId,
        formattedStartDate: "",
        formattedEndDate: "",
        startIndex,
      })
    )
      .then((response) => {
        const campaignDetails = response.payload.data; // Access the data array
        console.log("this is campaigndet", campaignDetails);
        // Create the modal content in tabular format
        setModalTitle("Campaign Scheme Details");
        const modalContent = (
          <div>
            <div className="table-responsive">
              {campaignDetails.length === 0 ? (
                "no data found"
              ) : (
                <table className="table table-bordered">
                  <thead>
                    <tr>
                      <th>
                        <strong>Benefit Type</strong>
                      </th>
                      <th>
                        <strong>Reward Bonus Points/Percentage</strong>
                      </th>
                      <th>
                        <strong>Scan From</strong>
                      </th>
                      <th>
                        <strong>Scan To</strong>
                      </th>
                      <th>
                        <strong>Range Condition</strong>
                      </th>
                      <th>
                        <strong>Transaction Points From</strong>
                      </th>
                      <th>
                        <strong>Transaction Points To</strong>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {campaignDetails.map((row) => {
                      console.log("row", row);
                      return (
                        <tr key={row.id}>
                          {" "}
                          {/* Assuming each row has a unique id */}
                          <>
                            <td>{row.benifitType}</td>
                            <td>{row.points}</td>
                          </>
                          <td>{row.scanFrom}</td>
                          <td>{row.scanTo}</td>
                          <td>{row.rangeCondition}</td>
                          <td>{row.volumeFrom}</td>
                          <td>{row.volumeTo}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              )}
            </div>
          </div>
        );

        setModalBody(modalContent); // Set the modal content
        setShowModal(true); // Show the modal
      })
      .catch((error) => {
        console.error("Error fetching campaign details:", error);
        setModalBody(<p>Failed to load campaign details.</p>);
        setShowModal(true);
      });
  };

  const handleViewUserDetails = (schemeId) => {
    //this views user details
    setSelectedSchemeId(schemeId);
    setModalTitle("User Details");
    setModalCurrentPage(1); // Reset to the first page
    setActionType("getUserDetails");
    setShowModal(true); // Open the modal
  };

  const handleUpdateStatus = (schemeId) => {
    console.log("schemeId", schemeId);
    setModalTitle("Update Configuration Status");
    setSelectedSchemeId(schemeId); // Store schemeId to use in handleCloseModal
    setModalBody(<p>Are you sure you want to deactivate the configuration?</p>);
    setActionType("updateStatus");
    setShowModal(true);
  };
  console.log("modalBody", modalBody);
  console.log("modaltitile", modalTitle);
  console.log("actionType", actionType);
  const handleOk = () => {
    if (actionType === "updateStatus" && selectedSchemeId) {
      const item = data.find((item) => item.schemeId === selectedSchemeId);

      if (item && item.status === "Active") {
        // Immediately update inactiveStatusMap to show the status as "Inactive"
        setInactiveStatusMap((prevMap) => ({
          ...prevMap,
          [selectedSchemeId]: true,
        }));

        const campStatus = "Inactive";

        dispatch(
          getupdateStatusCampaign({
            msLoggedUserId,
            msLoggedRoleId,
            token,
            campStatus,
            schemeId: selectedSchemeId,
          })
        )
          .then((response) => {
            if (response.payload?.status !== true) {
              // Revert the change if the API response indicates a failure
              setInactiveStatusMap((prevMap) => {
                const updatedMap = { ...prevMap };
                delete updatedMap[selectedSchemeId];
                return updatedMap;
              });
              console.error(
                "API response indicates failure:",
                response.payload?.message
              );
            } else {
              // Fetch updated data from the server to ensure consistency
              dispatch(
                getviewCampaignLuckyDrawData({
                  formattedStartDate,
                  formattedEndDate,
                  startIndex,
                  msLoggedUserId,
                  msLoggedRoleId,
                  token,
                })
              );
            }
          })
          .catch((error) => {
            // Revert change in case of an error
            setInactiveStatusMap((prevMap) => {
              const updatedMap = { ...prevMap };
              delete updatedMap[selectedSchemeId];
              return updatedMap;
            });
            console.error("Error updating campaign status:", error);
          });
      }
    }
    // Reset modal state
    setShowModal(false);
    setSelectedSchemeId(null);
    setModalBody(null);
    setActionType(null);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedSchemeId(null);
    setModalBody(null);
    setActionType(null);
  };

  const tabledata = data
    ? data.map((item, index) => {
        const isStatusInactive =
          inactiveStatusMap[item.schemeId] || item.status === "Inactive";
        console.log("isStatusInactive tabledata", isStatusInactive);
        return {
          "S.No.": (currentPage - 1) * itemsPerPage + index + 1,
          "Scheme ID": item.schemeId,
          "Campaign Type": item.campaign_type,
          "Contest Name": item.contest_name,
          "Benefit Type": item.benefit_type,
          "User Selection Type": item.user_selection,
          "Start Date": dayFirstDateFormat(item.start_date, false),
          "End Date": dayFirstDateFormat(item.end_date, false),
          "User Role": item.user_role,
          State: item.state,
          District: item.district,
          "Group Category": item.group,
          Brand: item.brand,
          "Sub Category": item.sub_category,
          Product: item.product,
          "User Preference": item.user_preference,
          "Contest Run Type": item.contest_runtype,
          "User Limit": item.user_limit,
          "Created Date": dayFirstDateFormat(item.created_date, true),
          Pincode: item.pincode,
          Status: isStatusInactive ? "Inactive" : "Active",
          "Scheme Segment Details": (
            <button
              onClick={() => handleViewDetails(item.schemeId)}
              className="campaign-btn camp-deta view-button"
            >
              View
            </button>
          ),
          "User Details": (
            <button
              onClick={() => handleViewUserDetails(item.schemeId)}
              className="campaign-btn user-deta view-button"
            >
              View
            </button>
          ),
          Actions: isStatusInactive ? (
            <p className="actions-update">Deactivated</p>
          ) : (
            <button
              onClick={() => handleUpdateStatus(item.schemeId)}
              className="campaign-btn update-deta deactivate-button"
            >
              Deactivate
            </button>
          ),
        };
      })
    : [];

  const handleSubmit = () => {
    dispatch(
      getviewCampaignLuckyDrawData({
        formattedStartDate,
        formattedEndDate,
        startIndex,
        campStatus: statusfilter,
        campType: campaignTypeFilter,
        msLoggedUserId,
        msLoggedRoleId,
        token,
      })
    );
  };

  return (
    <div className="container" style={{ minHeight: "81.8vh" }}>
      <div className="row">
        <div className="col-md-12 mt-5">
          <h2 className="text-capitalize fs-5 fst-italic">
            View Campaign/Lucky Draw Report
          </h2>
        </div>
        <div className="col-md-12">
          <Campaign_Filters
            startDate={startDate}
            endDate={endDate}
            setStartDate={setStartDate}
            setEndDate={setEndDate}
            stateItemSelected={stateItemSelected}
            handleStateChange={(e) => setStateItemSelected(e.target.value)}
            districtItemSelected={districtItemSelected}
            handleDistrictChange={(e) =>
              setDistrictItemSelected(e.target.value)
            }
            handleSubmit={handleSubmit}
            statusOptions={Status_Options}
            selectedStatus={statusfilter}
            handleStatusChange={handleStatusFilterChange}
            selectedCampaignType={campaignTypeFilter}
            campaignTypeOptions={campaignTypeOptions}
            handleCampaignTypeChange={handleCampaignTypeChange}
          />
        </div>
        <div className="col-md-12 my-4">
          <Common.CustomTable
            columns={columns}
            data={tabledata}
            message={message}
            totalRecords={totalRecords}
            itemsPerPage={itemsPerPage}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            totalPages={totalPages}
            startIndex={startIndex}
            endIndex={endIndex}
            handleViewDetails={handleViewDetails}
          />
        </div>
      </div>
      {/* Modal for viewing details */}
      <Modal_Component
        showModal={showModal}
        onClose={handleCloseModal}
        title={modalTitle}
        downloadButton={modalTitle === "User Details" ? true : false}
        downloadFunction={handleDownload}
        body={modalBody}
        onCancel={handleCloseModal}
        onOk={handleOk}
        fromDate=""
        toDate=""
      />
    </div>
  );
}

export default CampaignReport;
