// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SelectInput_selectInputContainer__4KOER {
  /* display: flex;
  justify-content: center;
  text-align: center; */
}
.SelectInput_label__JJ3xH {
  color: #000;
  font-size: 16px;
  display: inline-block;
  margin-bottom: 6px;
}
.SelectInput_selectInput__7w4Cf {
  width: 100%;
  display: inline-block;
  border-radius: 6px;
  padding: 8px 0px 8px 8px;
  background-color: white;
  color: #000;
  font-size: 0.875rem;
  border: "solid 1px"
}
.SelectInput_selectInput__7w4Cf:hover {
  
  border: "solid 1px";
  border-color: red;
}

.SelectInput_selectInput__7w4Cf option {
  background-color: #fff;
}
`, "",{"version":3,"sources":["webpack://./src/component/common/SelectInput/SelectInput.module.css"],"names":[],"mappings":"AAAA;EACE;;uBAEqB;AACvB;AACA;EACE,WAAW;EACX,eAAe;EACf,qBAAqB;EACrB,kBAAkB;AACpB;AACA;EACE,WAAW;EACX,qBAAqB;EACrB,kBAAkB;EAClB,wBAAwB;EACxB,uBAAuB;EACvB,WAAW;EACX,mBAAmB;EACnB;AACF;AACA;;EAEE,mBAAmB;EACnB,iBAAiB;AACnB;;AAEA;EACE,sBAAsB;AACxB","sourcesContent":[".selectInputContainer {\n  /* display: flex;\n  justify-content: center;\n  text-align: center; */\n}\n.label {\n  color: #000;\n  font-size: 16px;\n  display: inline-block;\n  margin-bottom: 6px;\n}\n.selectInput {\n  width: 100%;\n  display: inline-block;\n  border-radius: 6px;\n  padding: 8px 0px 8px 8px;\n  background-color: white;\n  color: #000;\n  font-size: 0.875rem;\n  border: \"solid 1px\"\n}\n.selectInput:hover {\n  \n  border: \"solid 1px\";\n  border-color: red;\n}\n\n.selectInput option {\n  background-color: #fff;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"selectInputContainer": `SelectInput_selectInputContainer__4KOER`,
	"label": `SelectInput_label__JJ3xH`,
	"selectInput": `SelectInput_selectInput__7w4Cf`
};
export default ___CSS_LOADER_EXPORT___;
