import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import appConfig from "../AppConfig";

const initialState = {
  loading: false,
  data: [],
  error: "",
};

export const getviewCampaignLuckyDrawData = createAsyncThunk(
  "getviewCampaignLuckyDrawData",
  async (
    {
      formattedStartDate,
      formattedEndDate,
      startIndex,
      campStatus,
      campType,
      msLoggedUserId,
      msLoggedRoleId,
      token
    },
    { rejectWithValue }
  ) => {
    const urls = `${appConfig.apiUrl}msViewCampaignLuckDrawScheme?msLoggedUserId=${msLoggedUserId}&msLoggedRoleId=${msLoggedRoleId}&token=${token}&fromDate=${formattedStartDate}&toDate=${formattedEndDate}&campStatus=${campStatus}&campType=${campType}&start=${startIndex}&length=10`;
    const headers = {
      "X-API-Key": appConfig.apiKey,
    };
    const response = await axios.get(urls, { headers: headers });
    try {
      const result = response.data;
      console.log("result", result);
      return result;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const viewCampaignLuckyDrawDetails = createSlice({
  name: "viewCampaignLuckyDrawDetails",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getviewCampaignLuckyDrawData.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getviewCampaignLuckyDrawData.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload;
      state.error = "";
    });
    builder.addCase(getviewCampaignLuckyDrawData.rejected, (state, action) => {
      state.loading = false;
      state.data = [];
      state.error = action.error.message;
    });
  },
});

export default viewCampaignLuckyDrawDetails.reducer;
