import React, { useEffect, useState } from "react";
import { Common } from "../../../component/common/common";
import { useDispatch, useSelector } from "react-redux";
import { getTransactionData } from "../../../features/transactionSlice";
import { getStateData } from "../../../features/stateSlice";
import { getDistrictData } from "../../../features/districtSlice";
import Filters from "../../../component/common/Filters/Filters";
import { useNavigate } from "react-router-dom";
import { downloadTransactionReports } from "../../../features/downloadTransactionReportSlice";
import appConfig from "../../../AppConfig";
import Button from "../../../component/Button/Button";
import Filters_updated from "../../../component/common/Filters/Filter_updated";
import { getSubCatDropDownData } from "../../../features/SubCatDropDownSlice";


function ViewTransaction() {
  const [downloading, setDownloading] = useState(false);
  const msLoggedUserId = localStorage.getItem("userId");
  const msLoggedRoleId = localStorage.getItem("roleId");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [selectSubcategory, setSubcategorytype] = useState("");
  const { data, totalRecords, message } = useSelector(
    (state) => state.transactionList.data
  );
  const loading = useSelector((state) => state.transactionList.loading);
  // console.log("Loadingasdada>>>>", loading);
  const SubCatDropDownList = useSelector((state) => state.SubCatDropDown.data);
  const stateList = useSelector((state) => state.stateList.data);
  const districtList = useSelector((state) => state.districtList.data);
  const [currentPage, setCurrentPage] = useState(1);
  const [stateItemSelected, setStateItemSelected] = useState("");
  const [districtItemSelected, setDistrictItemSelected] = useState("");

  const [mobileNumber, setMobileNumber] = useState("");
  // date
  const [startDate, setStartDate] = useState(() => {
    const today = new Date();
    today.setDate(today.getDate() - 1); // Subtract 1 day
    return today;
  });
  const [endDate, setEndDate] = useState("");

  
  const subcategory_options = SubCatDropDownList.map((item,index) => ({
    value: item.subCategory, 
    label: item.subCategory
  }));
  const formattedStartDate = startDate
    ? startDate.toLocaleDateString("en-CA", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    })
    : "";
  const formattedEndDate = endDate
    ? endDate.toLocaleDateString("en-CA", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    })
    : "";
  // table columns
  const columns = [
    "S.No.",
    "User Name",
    "User Code",
    "User Mobile Number",
    "User Pincode",
    "User District",
    "User State",
    "User Role",
    "User Creation Date",
    "Group Name",
    "Sub Category",
    "Brand",
    "Product Name",
    "Coupon Code",
    "Coupon Points",
    "Bonus Points",
    "Bonus Points Remarks",
    "Scheme Name",
    "Earned Source",
    "Check in date and time",
    "Check in date",
    "Transaction Type",
    "Point Earned",
    "Product Volume(L)"
  ];
  // table data
  const tableData = data
    ? data.map((item, index) => {
      const formatDOB = (dob) => {
        const parts = dob.split("-");
        return `${parts[2]}-${parts[1]}-${parts[0]}`;
      };
      // Format the check-in date without time and as "dd/mm/yyyy"
      const formatCheckinDate = (date) => {
        if (!date) return "";
        const parts = date.split(" ")[0].split("-"); // Splitting the date and time and only returning the date part
        return `${parts[2]}-${parts[1]}-${parts[0]}`; // Format as "dd/mm/yyyy"
      };
      // Format the check-in date with time as "dd-mm-yyyy hh:mm:ss AM/PM"
      const formatCheckinDateTime = (date) => {
        if (!date) return "";

        const d = new Date(date);
        const year = d.getFullYear();
        const month = String(d.getMonth() + 1).padStart(2, "0");
        const day = String(d.getDate()).padStart(2, "0");
        let hours = d.getHours();
        const minutes = String(d.getMinutes()).padStart(2, "0");
        const seconds = String(d.getSeconds()).padStart(2, "0");
        const ampm = hours >= 12 ? "PM" : "AM";
        hours = hours % 12;
        hours = hours ? hours : 12; // 0 should be converted to 12

        return `${day}-${month}-${year} ${hours}:${minutes}:${seconds} ${ampm}`;
      };

      return {
        "S.No.": (currentPage - 1) * 10 + index + 1,
        "User Name": item.name,
        "User Code": item.userCode,
        "User Mobile Number": item.mobileno,
        "User DOB": formatDOB(item.userDob),
        "User Email": item.userEmail,
        "User Pincode": item.userPincode,
        "User Address": item.userAddress,
        "User District": item.userDistrict,
        "User City": item.userCity,
        "User State": item.userState,
        "User Workshop": item.userWorkshop,
        "User Role": item.userRole,
        "User Pan Approved Status": item.pan_availability,
        "User Creation Date": formatCheckinDate(item.userCreatedOn),
        "Th Name": item.thName,
        "Th Mobile Number": item.thMobile,
        "Th Email": item.thEmail,
        "Sh Name": item.shName,
        "Sh Mobile Number": item.shMobile,
        "Sh Email": item.shEmail,
        "Rh Name": item.rhName,
        "Rh Mobile Number": item.rhMobile,
        "Rh Email": item.rhEmail,
        "Group Name": item.groupName,
        "Sub Category": item.subCategory,
         Brand: item.brand,
        "Product Name": item.productName,
        "Coupon Code": item.couponCode,
        "Coupon Points": item.couponPoints,
        "Bonus Points": item.bonusPoints,
        "Bonus Points Remarks": item.bonusRemarks,
        "Earned Source": item.earnedSource,
        "Check in date and time": formatCheckinDateTime(item.checkInDate),
        "Check in date": formatCheckinDate(item.checkInDate), //2024-01-30 15:36:46
        "Transaction Type": item.transactionType,
        "Point Earned": item.pointEarned,
        "Product Volume(L)": item.productVolume,
        "Scheme Name" : item.schemeName
      };
    })
    : [];

  const itemsPerPage = 10;
  const totalPages = Math.ceil(totalRecords / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  useEffect(() => {
    dispatch(
      getTransactionData({
        formattedStartDate,
        formattedEndDate,
        mobileNumber,
        districtItemSelected,
        stateItemSelected,
        startIndex,
        msLoggedUserId,
        msLoggedRoleId,
        selectSubcategory
      })
    );
  }, [dispatch, currentPage, msLoggedUserId, msLoggedRoleId]);

  useEffect(() => {
    dispatch(getStateData({ msLoggedUserId, msLoggedRoleId }));
  }, []);
  useEffect(() => {
    dispatch(
      getDistrictData({
        msLoggedUserId,
        msLoggedRoleId,
        selectedState: stateItemSelected,
      })
    );
  }, [stateItemSelected]);

  useEffect(() => {
    console.log("inside dispatch subcategory");
    dispatch(getSubCatDropDownData({ msLoggedUserId, msLoggedRoleId }));
  }, []);

  const handleSubcategoryChange = (e) => {
    setSubcategorytype(e.target.value);
  };

  // state Api call
  const handleSubmit = () => {
    dispatch(
      getTransactionData({
        formattedStartDate,
        formattedEndDate,
        mobileNumber,
        districtItemSelected,
        stateItemSelected,
        startIndex,
        msLoggedUserId,
        msLoggedRoleId,
        selectSubcategory
      })
    );
  };

  const handleClick = () => {
    navigate("/view-historic-transaction-report");
  };

  const handleStateChange = (e) => {
    setStateItemSelected(e.target.value);
  };
  const handleDistrictChange = (e) => {
    setDistrictItemSelected(e.target.value);
  };


  const handleDownload = () => {

    dispatch(
      downloadTransactionReports({
        formattedStartDate,
        formattedEndDate,
        mobileNumber,
        districtItemSelected,
        stateItemSelected,
        startIndex,
        msLoggedUserId,
        msLoggedRoleId,
      })
    )
      .then((response) => {
        const currentDate = new Date();
        const timestamp = currentDate.getTime();
        const formattedDate = currentDate.toISOString().slice(0, 10);
        const suggestedFileName = `view_transaction_reports_${formattedDate}_${timestamp}.csv`;
        const blob = new Blob([response.payload], { type: "text/csv" });

        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = suggestedFileName;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })
      .catch((error) => {
        console.error("Download error:", error);
      });
  };

  const handleDownloadClick = async () => {
    setDownloading(true);

    // Introducing a 1-second delay using setTimeout
    setTimeout(async () => {
      try {
        await handleDownload();
      } catch (error) {
        console.error("Error downloading file:", error);
      }
      setDownloading(false);
    }, 1000); // 1000 milliseconds = 1 second
  };
  const filterPage = "transaction"
  const handleDownloadClickFilter = async () => {

    navigate('/transaction-data-processing');
    const url = `${appConfig.apiUrl}msTransactionReportDownloadNew?msLoggedUserId=${msLoggedUserId}&msLoggedRoleId=${msLoggedRoleId}&fromDate=${formattedStartDate}&toDate=${formattedEndDate}&district=${districtItemSelected}&state=${stateItemSelected}&mobile=${mobileNumber}&subCategory=${selectSubcategory}`;

    const headers = {
      "X-API-Key": appConfig.apiKey,
      "Content-Type": "application/x-www-form-urlencoded",
    };

    try {
      const response = await fetch(url, {
        method: 'GET',
        headers: headers,
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      // Assuming the API response is in JSON format. Adjust if necessary.
      const data = await response.json();
      console.log('API response data:', data);

      // After successfully hitting the API, navigate to the desired route.

    } catch (error) {
      console.error('There was a problem with the fetch operation:', error);
    }
  };
  return (
    <div className="container" style={{ minHeight: "81.8vh" }}>
      <div className="row">
        <div className="col-md-12 mt-5">
          <div className="row">
            <div className="col-md-6">
              <h2 className="text-capitalize fs-5 fst-italic">
                View Transaction Reports
              </h2>
            </div>

            <div className="col-md-6 d-flex justify-content-end">
              {
              /* {msLoggedRoleId === "1" && (
                <div className="me-2">
                  <button
                    className="btn btn-outline-danger text-capitalize"
                    style={{
                      "--bs-btn-padding-y": ".25rem",
                      "--bs-btn-padding-x": ".5rem",
                      "--bs-btn-font-size": ".75rem",
                    }}
                    onClick={handleClick}
                  >
                    Historical Transaction Report
                  </button>
                </div>
              )} */
              
              }

              {(msLoggedRoleId === "1" || msLoggedRoleId === "3" || msLoggedRoleId === "7" || msLoggedRoleId === "8" || msLoggedRoleId === "9") && (
                <div>
                  {filterPage !== 'transaction' ? (
                    <>
                      {downloading ? (
                        <Button variant={"download"} disabled>
                          Downloading...
                        </Button>
                      ) : (
                        <Button variant={"download"} onClick={handleDownloadClick}>
                          Download
                        </Button>
                      )}
                    </>
                  ) : (
                    <Button variant={"download"} onClick={handleDownloadClickFilter}>
                      Download
                    </Button>
                  )}
                </div>
              )}
            </div>
          </div>


        </div>
        <div className="col-md-12">
          <Filters_updated
            startDate={startDate}
            endDate={endDate}
            setStartDate={setStartDate}
            setEndDate={setEndDate}
            mobileNumber={mobileNumber}
            setMobileNumber={setMobileNumber}
            stateItemSelected={stateItemSelected}
            handleStateChange={handleStateChange}
            stateList={stateList}
            districtList={districtList}
            districtItemSelected={districtItemSelected}
            handleDistrictChange={handleDistrictChange}
            handleSubmit={handleSubmit}
            subcategoryoptions={subcategory_options}
            handleSubcategoryChange={handleSubcategoryChange}
            selectedSubcategory={selectSubcategory}
          />
        </div>
        {loading ? (
          <div className="d-flex justify-content-center mt-4">
            <div className="spinner-border" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        ) : (
          <div className="col-md-12 my-4">
            <Common.CustomTable
              columns={columns}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              data={tableData}
              itemsPerPage={itemsPerPage}
              totalPages={totalPages}
              startIndex={startIndex}
              endIndex={endIndex}
              totalRecords={totalRecords}
              message={message}
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default ViewTransaction;
