import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import appConfig from "../AppConfig";

const initialState = {
  data: [],
  loading: false,
  error: "",
};
// getDashboardData
export const getPincodeData = createAsyncThunk(
  "getPincodeData",
  async (
    { selectedDistricts,selectedStates, msLoggedUserId, msLoggedRoleId },
    { rejectWithValue }
    
  ) => {
    if (selectedDistricts.length === 0) {
      return []; 
    }
    if (selectedStates.length === 0) {
        return []; 
      }
    const districtParam = selectedDistricts.map(district => `district[]=${encodeURIComponent(district)}`).join("&");
    const stateParam = selectedStates.map(state => `state[]=${encodeURIComponent(state)}`).join("&");
    


    const urls = `${appConfig.apiUrl}msGetPincodeData?msLoggedUserId=${msLoggedUserId}&msLoggedRoleId=${msLoggedRoleId}&${stateParam}&${districtParam}`;
    console.log("Request URL:", urls);
    const headers = {
      "X-API-Key": appConfig.apiKey,
    };
    const response = await axios.get(urls, {
      headers: headers,
    });
    try {
      const result = await response.data.data;
      console.log("pincode slice", result);

      return result;
           
      
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const pincodeList = createSlice({
  name: "pincodeList",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getPincodeData.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getPincodeData.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload;
      state.error = "";
    });
    builder.addCase(getPincodeData.rejected, (state, action) => {
      state.loading = false;
      state.data = [];
      state.error = action.error.message;
    });
  },
});

export default pincodeList.reducer;
